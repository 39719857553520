<template>
  <b-sidebar
    id="add-new-company-sidebar"
    :visible="isAddNewCompanySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-company-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? " Add New Company" : actionType == 1 ? " Update Company" : "Delete Company"}}
         
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >

        <b-form-group label="Client">
                <v-select
                        id="client"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        v-model="currentClient"
                        label="name"
                        :options="clients"
                        :disabled="actionType==2"
                        class="select-size-lg">
                <template #option="{ id, name }">
                    <span> {{ name }}</span><br />
                    <small class="text-muted"> {{ id }} </small>
                </template>
                </v-select>
            </b-form-group>

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group
              label="Company Name"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="currentCompany.name"
                :disabled="actionType==2"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Company Name"
              />
              </b-form-group>

              <b-form-checkbox 
                class="mt-2"
                :disabled="actionType==2"
                id="input-isActive"
                v-model="currentCompany.isActive"
              >
                IsActive
              </b-form-checkbox>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            
          </validation-provider>
     
          <!-- <category-selector :initial-id="currentCategory==null||currentCategory.upperCategory==null?null:currentCategory.upperCategory"  @selectionChanged="selectionChanged" ></category-selector> -->
       
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-if="actionType != 2"
              class="mr-2"
              type="submit"
            >
             {{ currentCompany==null|| currentCompany.id==null? 'Add':'Update'}} 
            </b-button>
             <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-2"
              v-if="currentCompany!=null&& currentCompany.id!=null && actionType == 2"
              variant="outline-danger"
              @click="deleteCompany"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {inject,toRefs, ref,watch } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
// import CategorySelector from '../components/SingleCategorySelector.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormSelect,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    // CategorySelector
  },
   created() {
    this.GetClients();
  },
   methods:{
    GetClients() {
      this.$store.dispatch('pamis-company/fetchClients')
        .then((result) => {
          if (result != null) {
            var clientList = result.data.filter(x => x.isDelete == false && x.isActive == true)
            this.clients = clientList
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Clients Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    deleteCompany(){
       this.$store.dispatch('pamis-company/deleteCompany', {id : this.currentCompany.id})
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-company-sidebar-active', false)
          this.notification()
        });
    },

    submitForm(){
      if (this.currentClient == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Client Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return false;
      }

      var process = "pamis-company/addCompany";

      if(this.currentCompany !=null &&  this.currentCompany.id != null){
        process = "pamis-company/updateCompany";
      } 
        
      this.currentCompany.clientId = this.currentClient.id;
      
       this.$store.dispatch(process, this.currentCompany)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-company-sidebar-active', false)
          this.notification()
        });
    },
    notification() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Process Successfully',
          icon: 'BellIcon',
        },
      })
    },
  },
  
  watch: {
     companydata:   {
        handler:function (n, o)   {
          if (n==null||n==undefined)
          {
            this.currentCompany={
              Id:null,
              name:null,
              clientId:null,
              isActive:false,
            }
          } else
          { 
            this.currentCompany = n.selectedCompany;

            if (n.selectedCompany.id != null) {
              this.currentClient = this.clients.filter(x => x.id == n.selectedCompany.clientId)[0]
            } else {
              this.currentClient = null;
            }
          }
        },
        deep: true
    }
},
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewCompanySidebarActive',
    event: 'update:is-add-new-company-sidebar-active',
  },
  props: {
    isAddNewCompanySidebarActive: {
      type: Boolean,
      required: true,
    },
     actionType: {
      type: Number,
      required: true,
    },
  },
   
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      clients :[],
      currentClient :null,
      currentCompany:{
        Id:null,
        Name:null,
        clientId:null,
        isActive:false,
      },
    }
  },
   
  inject: ['companydata'],

  setup(props,  { emit }) {
    const blankCompanyData = {
      id:null,
      name:null,
      clientId:null
    }

    const companyData = ref(JSON.parse(JSON.stringify(blankCompanyData)))
    const resetCompanyData = () => {
      companyData.value = JSON.parse(JSON.stringify(blankCompanyData))
      emit('refetch-data')
      emit('update:is-add-new-company-sidebar-active', false)
    }
      
    const onSubmit = () => {
      store.dispatch('pamis-company/addCompany', currentCompany)
        .then(() => {
        })
    }
     
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetCompanyData)

    return {
      companyData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-company-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
