import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCompanies(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Company')
          .then((response)=> { 

            return resolve(response)})
          .catch(error => reject(error))
      })
    },
    fetchClients(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Client')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCompany(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/organization/Company/Create',  data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCompany(ctx, { id }) {
       return new Promise((resolve, reject) => {
         axios
           .delete('pamis/admin/organization/Company/Delete?id='+id)
           .then(response => resolve(response))
           .catch(error => reject(error))
       })
     },
     updateCompany(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('pamis/admin/organization/Company/Update?id='+data.id, data )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
